import { FC, memo } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';

import { DEFAULT_LOCALE } from '@/constants/global';
import { Route } from '@/constants/routes';
import { LoginPageStatus } from '@/types';
import { getServerCookies } from '@/utils/getServerCookies';
import { withoutAuth } from '@/utils/withoutAuth';
import { Login, NavLinks } from '@/widgets/Login';

type Props = {
  isDefaultLogged: boolean;
};

const LoginPage: FC<Props> = memo(({ isDefaultLogged }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleChangePage = (page: LoginPageStatus) => {
    if (page !== LoginPageStatus.Login) {
      router.push(Route.Register);
    }
  };

  return (
    <>
      <NextSeo
        description={t('loginPage.description')}
        openGraph={{
          title: t('loginPage.title'),
          description: t('loginPage.description'),
        }}
        title={t('loginPage.title')}
      />
      <div
        className="bg-gray-100 px-6 pb-10 pt-6 md:p-14 md:pt-6
mt-32 md:mt-36 lg:mt-44 xl:mt-44 max-w-4xl w-full mx-auto">
        <NavLinks currentPage={LoginPageStatus.Login} onClick={handleChangePage}>
          <Login isDefaultLogged={isDefaultLogged} />
        </NavLinks>
      </div>
    </>
  );
});

export const getServerSideProps = withoutAuth(async (context) => {
  const { locale } = context;

  return {
    props: {
      ...(await serverSideTranslations(locale || DEFAULT_LOCALE, ['default'])),
      ...getServerCookies(context),
    },
  };
});

export default LoginPage;
